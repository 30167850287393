<template>
  <div>
    <img
      src="../../assets/img/back-button.svg"
      class="pointer-on-hover back-button-margin"
      @click="$router.go(-1)"
    />
    <CRow>
      <CCol col="12" xl="12">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader>
                <CRow>
                  <CCol col="3" md="3">
                    <h5 class="mt-2">
                      <div class="text-muted table-labels">
                        {{ $t("ORDERS.DETAILS_CARD.HEADER.ORDER_ID") }}
                      </div>
                      <div class="id-text">{{ id }}</div>
                    </h5>
                  </CCol>
                  <CCol col="2" md="2">
                    <h5 class="mt-2">
                      <div class="text-muted table-labels">
                        {{ $t("ORDERS.DETAILS_CARD.HEADER.REMAINING_PAYMENT") }}
                      </div>
                      Rp. {{ formatAmount(pending_amount) ? formatAmount(pending_amount) : "0" }}
                    </h5>
                  </CCol>
                  <CCol col="2" md="2">
                    <h5 class="mt-2">
                      <div class="text-muted table-labels">
                        {{ $t("ORDERS.DETAILS_CARD.HEADER.PAYMENT_OPTION") }}
                      </div>
                      {{ capatalizeAndRemoveUnderScore(payment_option) }}
                    </h5>
                  </CCol>
                  <CCol col="2" md="2">
                    <h5 class="mt-2">
                      <div class="text-muted table-labels">
                        {{ $t("ORDERS.DETAILS_CARD.HEADER.STATUS") }}
                      </div>
                      <CBadge
                        :color="getPaymentBadge(status)"
                        class="status-badge font-bold status-header"
                      >
                        {{ status }}
                      </CBadge>
                    </h5>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody class="p-0 pl-4">
                <CRow>
                  <CCol sm="6" class="border-right p-4">
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.REFERENCE_ID") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div>{{ order_ref_id }}</div>
                      </CCol>
                    </CRow>
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.TOTAL_AMOUNT") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div>Rp. {{ formatAmount(amount) }}</div>
                      </CCol>
                    </CRow>
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.DISCOUNT_AMOUNT") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div>Rp. {{ formatAmount(discount) }}</div>
                      </CCol>
                    </CRow>
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.NET_AMOUNT") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div class="faded-value">
                          Rp. {{ formatAmount(getNetAmount()) }}
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="6" class="p-4">
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.MODE") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div>{{ is_live ? "Live" : "Sandbox" }}</div>
                      </CCol>
                    </CRow>
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.CREATED_AT") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div>{{ formatDate(created_at) }}</div>
                      </CCol>
                    </CRow>
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.UPDATED_AT") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div>{{ formatDate(updated_at) }}</div>
                      </CCol>
                    </CRow>
                    <CRow class="padding-around">
                      <CCol>
                        <span class="card-labels">
                          {{ $t("ORDERS.DETAILS_CARD.CUSTOMER_ID") }}
                        </span>
                      </CCol>
                      <CCol>
                        <div
                          class="id-text-small pointer-on-hover"
                          @click="goTo('/customers/' + customer_id)"
                        >
                          {{ customer_id }}
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader class="table-card-header">
                {{ $t("ORDERS.PAYMENT_TABLE.0") }}
              </CCardHeader>
              <CCardBody>
                <payment-history :payments="payments" />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import PaymentHistory from "./components/PaymentHistory";
import { getOrder } from "@/api/order.api.js";
export default {
  name: "OrderDetails",
  data() {
    return {
      id: null,
      pending_amount: null,
      payment_option: null,
      status: null,
      order_ref_id: null,
      amount: null,
      discount: null,
      is_live: null,
      created_at: null,
      updated_at: null,
      customer_id: null,
      payments: [],
    };
  },
  components: {
    PaymentHistory,
  },
  methods: {
    async getOrderDetailsByID() {
      try {
        const response = await getOrder(
          this.$route.params.id,
          this.generateExpandParams(["payments", "customer"])
        );
        const data = response.data.data;
        this.id = data.id;
        this.pending_amount = data.pending_amount;
        this.payment_option = data.payment_option;
        this.status = data.status;
        this.amount = data.amount;
        this.is_live = data.is_live;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.customer_id = data.customer_id;
        this.order_ref_id = data.order_ref_id;
        this.payments = data.payments;
        this.setCompletedPaymentDetails()
      } catch (error) {
        this.showToaster(this.$t("ORDERS.ERRORS.GET_ORDER"));
      }
    },
    async setCompletedPaymentDetails() {
      let isCompleted = [];
      if (this.payments && this.payments.length > 0) {
        isCompleted = await this.payments.filter(
          (payment) => payment["status"] === "completed"
        );
      }
      if (isCompleted.length > 0) {
        this.discount = isCompleted[0]["discount"]
      }
    },
    getNetAmount() {
      return Number(this.amount) - Number(this.discount)
    }
  },
  mounted() {
    this.getOrderDetailsByID();
  },
};
</script>

<style scoped>
.table-labels {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 8px;
}
.back-button-margin {
  margin-bottom: 2%;
}
.padding-around {
  padding-bottom: 8px;
}
.card-labels {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4e4f52;
}
.table-card-header {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #4e4f52;
}
.id-text {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #418af8;
}
.id-text-small {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #418af8;
}
.status-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
</style>