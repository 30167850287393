<template>
  <div class="table-responsive">
    <table class="table">
      <thead class="table-header">
        <tr>
          <td class="header-row">{{ $t("ORDERS.PAYMENT_TABLE.1") }}</td>
          <td class="header-row">{{ $t("ORDERS.PAYMENT_TABLE.2") }}</td>
          <td class="header-row">{{ $t("ORDERS.PAYMENT_TABLE.3") }}</td>
          <td class="header-row">{{ $t("ORDERS.PAYMENT_TABLE.5") }}</td>
          <td class="header-row">{{ $t("ORDERS.PAYMENT_TABLE.6") }}</td>
          <td class="header-row">{{ $t("ORDERS.PAYMENT_TABLE.7") }}</td>
        </tr>
      </thead>
      <tbody v-if="payments.length > 0">
        <tr v-for="(value, key) in payments" :key="key">
          <td
            class="header-row id-text-small pointer-on-hover"
            @click="goTo('/payments/' + value.id)"
          >
            {{ value.id }}
          </td>
          <td class="header-row">{{ getPaymentMethod(value.payment_details_type, value.method_id) }}</td>
          <td class="header-row">Rp. {{ formatAmount(value.amount) }}</td>
          <td class="header-row">{{ $t("IDR") }}</td>
          <td class="header-row">{{ formatDate(value.created_at) }}</td>
          <td class="header-row">
            <CBadge
              :color="getPaymentBadge(value.status)"
              class="status-badge font-bold status-header"
            >
              {{ value.status }}
            </CBadge>
          </td>
        </tr>
      </tbody>
      <tbody v-if="payments.length === 0">
        <img
          src="../../../assets/img/notFound.svg"
          class="center"
          alt="default.svg"
          height="200px"
          width="100%"
        />
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "PaymentHistory",
  props: { payments: Array },
};
</script>
<style scoped>
.id-text-small {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #418af8;
}
.status-badge {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.center {
  display: block;
  margin-left: 125%;
  margin-top: 10%;
}
</style>